/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'tailwindcss/dist/base.css';

import { anchorate } from 'anchorate';

// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// Anchor links management
// https://github.com/AndersDJohnson/anchorate#gatsby
export const onRouteUpdate = () => {
  anchorate();
};
